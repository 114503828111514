
import moment from 'moment';

export default {
  name: 'SearchMaisonsAndActivities',
  data() {
    return {
      locationName: '',
      form: {
        location: '',
        date: null,
        persons: {
          adults: 2,
          children: 0,
        },
      },
      locations: [],
      recommendations: this.$helpers.getRecommendations(),
      availableDates: [],
    };
  },
  methods: {
    async submit() {
      let query = {};

      if (this.form.location) {
        query.fromPoint = `${this.form.location},25`;
        query.sort_by = `asc:distance`;
        query.location = this.locationName;
      }

      if (this.form.date) {
        query.date = moment(this.form.date).format('YYYY-MM-DD');
      }

      if (this.form.persons.adults > 0) {
        let total =
          parseInt(this.form.persons.adults) +
          parseInt(this.form.persons.children);

        query['guests[0]'] = `gte:${total}`;
      }

      if (this.form.persons.children > 0) {
        query.children = true;
      }

      this.$router.push(
        this.localePath({
          name: 'maisons',
          query: query,
        })
      );
    },
    setAvailableDates(date) {
      const now = moment().hour(0).minute(0).second(0);

      const start = moment(date)
        .startOf('month')
        .startOf('week')
        .startOf('day');

      const end = moment(date)
        .add('1', 'month')
        .endOf('month')
        .endOf('week')
        .endOf('day');

      const dates = [];

      while (start.unix() < end.unix()) {
        if (start.unix() < now.unix()) {
          start.add(1, 'day');
          continue;
        }

        dates.push(start.format('DD-MM-YYYY'));

        start.add(1, 'day');
      }

      this.availableDates = dates;
    },
    setLocation(location) {
      this.locationName = location;
    },
  },
  computed: {
    formattedDate() {
      let date = this.form.date;

      if (!date) {
        return null;
      }

      return moment(date).locale(this.$i18n.locale).format('DD-MM-YYYY');
    },
    formattedPersons() {
      let text = '';
      let persons = this.form.persons;

      for (let person in persons) {
        let value = persons[person];

        if (value < 1) {
          continue;
        }

        if (!!text) {
          text = text.concat(', ');
        }

        text = text.concat(
          `${value} ${this.$tc(`general.${person}-pluralization-low`, value)}`
        );
      }

      return text;
    },
  },
  watch: {
    'form.date'() {
      this.$refs.calendarDropdown?.hide();
    },
  },
};
