
export default {
  name: 'FormulateQuantity',
  props: {
    label: {
      type: String,
    },
    value: {
      type: Number,
    },
    validation: {
      type: String,
    },
  },
  data() {
    return { number: this.value };
  },
  inheritAttrs: false,
  methods: {
    increment() {
      this.number = this.value ? this.value + 1 : 1;
    },
    decrement() {
      this.number = this.value > 0 ? this.value - 1 : 0;
    },
  },
  watch: {
    number(value) {
      this.$emit('input', Number(value));
    },
    value(value) {
      this.number = value;
    },
  },
};
