
import _ from 'lodash';

export default {
  name: 'FormulateLocation',
  props: {
    value: {
      type: String,
    },
    location: {
      type: String,
      default: '',
    },
    limit: {
      type: Number,
      default: 10,
    },
    name: {
      type: String,
    },
    country: {
      type: String | null,
      default: null,
    },
    bbox: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    validation: {
      type: String,
    },
    label: {
      type: String,
    },
    withClear: {
      type: Boolean,
    },
    recommendations: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      timeout: null,
      query: '',
      currentValue: this.value,
      features: [],
      isFetching: false,
      maisons: [],
    };
  },
  async mounted() {
    const { data: maisons } = await this.$api.$get('/api/v1/maisons/search');
    this.maisons = maisons ?? [];

    if (!this.currentValue) {
      return;
    }

    // Reverse back so lng is first and than lat. Because of mapbox.
    let denormalizedValue = this.currentValue.split(',').reverse();

    const { features } = await this.fetchFromMapbox(denormalizedValue);

    if (features.length > 0) {
      const feature = features[0];
      this.query = this.location ?? feature.text;
      this.currentValue = feature.center;
    }
  },
  methods: {
    search(e) {
      this.fetchFeatures(e.target.value);
    },
    selectFeature(feature, hide) {
      this.query = feature.text;
      this.currentValue = feature.center;
      this.$emit('update-location', this.query);
      hide();
    },
    selectRecommendation(recommendation, hide) {
      this.query = recommendation.place;
      this.currentValue = recommendation.coordinates;
      this.$emit('update-location', this.query);
      hide();
    },
    fetchFeatures(value) {
      this.isFetching = true;
      clearTimeout(this.timeout);

      if (!!value && value?.length < 2) {
        this.isFetching = false;
        this.features = [];
        return;
      }

      this.timeout = setTimeout(async () => {
        const { features } = await this.fetchFromMapbox(value);

        const fakeFeatures = this.getMaisonFeatures(value);

        this.features = [
          ...fakeFeatures,
          ...features.filter((feature) => feature['place_type'][0] !== 'poi'),
        ];
        this.isFetching = false;
      }, 400);
    },
    async fetchFromMapbox(value) {
      const params = new URLSearchParams();
      if (!!this.country) {
        params.append('country', this.country);
      }
      if (!!this.bbox) {
        params.append('bbox', this.bbox);
      }
      // params.append('limit', this.limit);
      params.append('language', this.$i18n.locale);
      params.append('access_token', process.env.mapboxAccessKey);

      return await this.$axios.$get(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
          value
        )}.json?${params}`
      );
    },
    clear() {
      this.currentValue = '';
      this.features = [];
      this.query = '';
    },
    // This should actually be a prop to have the choice of different externals.
    getMaisonFeatures(value) {
      return _.chain(this.maisons)
        .filter((maison) =>
          maison.name?.toLowerCase()?.includes(value?.toLowerCase())
        )
        .map((maison) => ({
          text: `🥂 ${maison.name}`,
          center: maison.center,
        }))
        .take(10)
        .value();
    },
  },
  watch: {
    currentValue(value) {
      if (!value) return;

      // Reverse lat with lng because the api needs the reverse method.
      let normalizedValue = [...value].reverse().join(',');

      this.$emit('input', normalizedValue);
    },
    location(location) {
      this.query = this.location;
    },
  },
};
